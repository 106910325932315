<template>
  <div
    v-if="isDirectOrderDataLoading"
    class="d-flex justify-content-center mb-1"
    style="padding-top: 30vh"
  >
    <b-spinner class="align-middle text-info" />
  </div>
  <div v-else>
    <b-breadcrumb class="mb-2 ">
            <b-breadcrumb-item to="/orders/recurring" >Packages</b-breadcrumb-item>
            <b-breadcrumb-item>
             <router-link :to="{ name: 'commande', params: { id: this.$route.query.packageId } }">Commandes</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item>
             <router-link :to="{ name: 'admin-orders-recurring-details', params: { id: this.$route.params.id }, query: { packageId: this.$route.query.packageId } }">Détails</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>Modifier</b-breadcrumb-item>
        </b-breadcrumb>
    <b-card>
      <validation-observer #default="{}" ref="modifyOrder">
        <!-- body -->
        <b-form id="new-client-form" @submit.prevent="applyModifyOrder()">
          <b-row>

            <!-- Search employee -->
            <b-col md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Recherche ou gestion d'employé"
                rules="required"
              >
                <b-form-radio-group
                  v-model="payload.search_employee"
                  :options="typeOptions"
                  :state="errors.length > 0 ? false : null"
                  value-field="item"
                  text-field="name"
                  class="demo-inline-spacing custom-control-success"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Service recherché"
                rules="required"
              >
               <b-form-group label="Service recherché" label-for="services">
                  <v-select id="role" v-model="payload.service_id" :reduce="(service) => service.id" label="name"
                    :options="recurringServicesOption" :state="errors.length > 0 ? false : null">
                    <template v-slot:no-options>
                      <b-spinner v-if="isServicesRecurringWithoutPaginationLoading"
                        style="width: 2.5rem; height: 2.5rem" class="align-middle text-info" />

                      <span v-else class="mt-1 font-medium-1">Ce service n'existe pas</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Budget -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :name="payload.budget_is_fixed === 1? 'Budget global':'Salaire net'"
                rules="required"
              >
                <b-form-group  :label="payload.budget_is_fixed === 1? 'Budget global':'Salaire net' "
                 :label-for="payload.budget_is_fixed === 1?'employee_brut_salary': 'employee_salary' ">
                  <b-form-input
                  :id="inputId" 
                  v-model="InputVmodel"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="payload.budget_is_fixed === 1? 'Entrez le buget global': 'Entrez le salaire net de l\'employé'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- Intervention frequency -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Fréquence d'intervention"
                rules="required"
              >
                <b-form-group
                  label="Fréquence d'intervention"
                  label-for="intervention-frequency"
                >
                  <b-form-select
                    id="intervention-frequency"
                    v-model="payload.intervention_frequency"
                    :options="interventionFrequencyOptions"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
             <!-- Nombre d'employés -->
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Nombre d'employé" rules="required">
                <b-form-group label="Nombre d'employé" label-for="number_of_employees">
                  <b-form-input id="number_of_employees" v-model="payload.number_of_employees" type="number"
                    :state="errors.length > 0 ? false : null" placeholder="Entrez le nombre d'employé" />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
             <!--Client's employees -->
            <b-col md="6" v-if="payload.search_employee === 0"> 
              <b-row>
                <b-col cols="12" class="pr-1">
            
                  <validation-provider #default="{ errors }" name="Employee" rules="required">
                    <b-form-group label="Employee" label-for="Employee">
                      <v-select multiple id="employee" v-model="payload.employees" :reduce="(employee) => employee.id" label="name"
                        :options="employeeOptions" :state="errors.length > 0 ? false : null">
                        <template v-slot:no-options>
                          <b-spinner v-if="isCustomersWithoutPaginationLoading" style="width: 2.5rem; height: 2.5rem"
                            class="align-middle text-info" />
                          <div v-else>
                            <span class="mt-1 font-medium-1">Aucun employé ne correspond à ces détails</span>
                          </div>
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
             <!-- CNSS -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Cnss"
                rules="required"
              >
                <b-form-group
                  label="Déclarer à la cnss"
                  label-for="cnss"
                >
                  <b-form-select
                    id="cnss"
                    v-model="payload.cnss"
                    :options="cnssOptions"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- Other needs -->
            

            <!-- Offer type -->
            <!-- <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Type d'offre"
                rules="required"
              >
                <b-form-group label="Type d'offre" label-for="offer-type">
                  <b-form-select
                    id="offer-type"
                    v-model="payload.offer_type_id"
                    :options="offerTypeOptions"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col> -->

            <!-- Address -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="quartier du client"
                rules="required"
              >
                <b-form-group label="Quartier du client" label-for="address">
                  <b-form-input
                    id="address"
                    v-model="payload.address"
                    placeholder="Quartier du client"
                    :state="errors.length > 0 ? false : null"
                    autocomplete="off"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <!-- EXONERATION -->
            <b-col md="6" v-if="$route.query.exoneration == 'false'">
              <validation-provider #default="{ errors }" name="exoneration" >
                <b-form-group label="Exonération des frais de placement" label-for="placement_fees_is_exonerated">
                  <b-form-select id="placement_fees_is_exonerated" v-model="payload.placement_fees_is_exonerated" :options="exonerationOptions"
                    :state="errors.length > 0 ? false : null" />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- Budget fixe -->
            <b-col md="6" v-if="payload.cnss === 0">
              <validation-provider #default="{ errors }" name="budget_fixe" >
                <b-form-group label="Budget fixe" label-for="budget_is_fixed">
                  <b-form-select id="budget_is_fixed" v-model="payload.budget_is_fixed" :options="fixedBudgetOptions"
                    :state="errors.length > 0 ? false : null" />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>


            <!-- Other needs -->
            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                name="Cahier de charges"
                rules= "required"
              >
                <b-form-group label="Cahier de charges" label-for="needs">
                  <b-form-textarea
                    id="needs"
                    v-model="payload.other_wish"
                    placeholder="Cahier de charges du client"
                    rows="5"
                    no-resize
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- submit and reset -->
            <b-col class="mt-2" md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="isModifyOrderLoading"
              >
                <div v-if="isModifyOrderLoading">
                  <span> Chargement ... </span>
                  <b-spinner small />
                </div>
                <span v-else class="text-nowrap font-medium-1">Modifier</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormSelect,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BSpinner,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.bj";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Cleave,
    BSpinner,
    BBreadcrumb,
    BBreadcrumbItem,
  },

  directives: {
    Ripple,
  },

  data() {
    return {

      isModifyOrderLoading: false,

      // isRecurringServicesLoading: true,

      required,

      recurringServicesOption: [],

      directOrder: null,

      typeOptions: [
        { item: 1, name: "À la recherche d'employé" },
        // { item: 0, name: "Confier la gestion d'employé" },
      ],

      exonerationOptions: [
        {
          value: 1,
          text: "Oui",
        },
        {
          value: 0,
          text: "Non",
        },
      ],

      fixedBudgetOptions: [
        {
          value: 1,
          text: "Oui",
        },
        {
          value: 0,
          text: "Non",
        },
      ],
      budgetOptions: [
        {
          value: "Entre 15000 & 50000",
          text: "Entre 15000 & 50000",
        },
        {
          value: "Entre 50000 & 80000",
          text: "Entre 50000 & 80000",
        },
        {
          value: "Entre 80000 & 120000",
          text: "Entre 80000 & 120000",
        },
        {
          value: "Entre 120000 & plus",
          text: "Entre 120000 & plus",
        },
      ],
       cnssOptions: [
        {
          value: 1,
          text: 'Oui',
        },
        {
          value: 0,
          text: 'Non',
        },
      ],
      interventionFrequencyOptions: [
        {
          value: 7,
          text: "Tous les jours",
        },
        {
          value: 1,
          text: "1 fois par semaine",
        },
        {
          value: 2,
          text: "2 fois par semaine",
        },
        {
          value: 3,
          text: "3 fois par semaine",
        },
        {
          value: 4,
          text: "4 fois par semaine",
        },
        {
          value: 5,
          text: "5 fois par semaine",
        },
        {
          value: 6,
          text: "6 fois par semaine",
        },
        
      ],

      // offerTypeOptions: [
      //   {
      //     value: 1,
      //     text: "Confort",
      //   },
      //   {
      //     value: 2,
      //     text: "Premium",
      //   },
      // ],

      frequency: "",
      other_intervention_frequency: "",
      employeeOptions: [],
      formEmployees: [],
      payload: {
        search_employee: null,
        service_id: null,
        employee_salary: "",
        intervention_frequency: "",
        address: "",
        other_wish: "",
        cnss: "",
        number_of_employees: '',
        employees: []
      },
      phone: "",

      options: {
        phone: {
          phone: true,
          phoneRegionCode: "BJ",
        },
      },

      isDirectOrderDataLoading: true,

      isFrequencyInterventionValuesMatched: false,
    };
  },

  computed: {
    ...mapGetters('services', ['getRecurringServices', 'getRecurringServicesWithoutPagination']),
    ...mapGetters(["isRecurringServicesLoading"]),
    ...mapGetters("orders", ["getDirectOrder"]),
    ...mapGetters("customers", ["getNewCustomer"]),
    ...mapGetters(['isServicesRecurringWithoutPaginationLoading']),

    inputId(){
      return this.payload.budget_is_fixed === 1?  'employee_brut_salary': 'employee_salary'
     
    },

    InputVmodel: {
      get() {
        return this.payload.budget_is_fixed === 1? this.payload.employee_brut_salary : this.payload.employee_salary

      },
      set(newValue) {

          if (this.payload.budget_is_fixed === 1) {
            this.payload.employee_brut_salary = newValue;
        } else {
          this.payload.employee_salary = newValue;
        }
                
      },
    },
  },

  watch: {
   isServicesRecurringWithoutPaginationLoading(val) {
      if (val === false) {
        this.getRecurringServicesWithoutPagination.forEach(element => {
          this.recurringServicesOption.push({
            id: element.id,
            name: element.name,
          })
        })
      }
    },
    // getNewCustomer(val) {
    //   if (val !== null) {
    //     this.clientData = val;
    //   }
    // },
  },

  created() {
    this.applyGetDirectOrderData()
    try{
       if (this.getDirectOrder !== null) {
      this.directOrder = this.getDirectOrder;
      console.log('DIRECT OREDER',this.directOrder)
       this.isDirectOrderDataLoading = false;
      this.payload = {
        search_employee: this.directOrder.search_employee ? 1 : 0,
        service_id: this.directOrder.recurring_service_id,
        employee_salary: this.directOrder.employee_salary,
        employee_brut_salary: this.directOrder.employee_brut_salary,
        intervention_frequency: this.directOrder.intervention_frequency,
        address: this.directOrder.address,
        other_wish: this.directOrder.other_wish,
        cnss: this.directOrder.cnss ? 1 : 0,
        placement_fees_is_exonerated: this.directOrder.placement_fees_is_exonerated ? 1 : 0,
        budget_is_fixed: this.directOrder.budget_is_fixed ? 1 : 0,
        number_of_employees: this.directOrder.number_of_employees,
        employees: this.directOrder.employees
      };
      
     
    } else {
      this.applyGetDirectOrderData();
    }
    }
    catch(error){
     console.log(error)

    }
   
    // this.getClientEmployees()
  },

  mounted() {
     if (this.getRecurringServicesWithoutPagination.length != 0) {
      this.getRecurringServicesWithoutPagination.forEach(element => {
        this.recurringServicesOption.push({
          id: element.id,
          name: element.name,
        })
      })
      
    }
    this.configProAddressField();

     if (this.formEmployees.length != 0) {
      this.formEmployees.forEach(element => {
        this.employeeOptions.push({
          id: element.id,
          name: element.full_name,
        })
      })
    }
    console.log(this.employeeOptions)
  },

  methods: {
    ...mapActions("orders", [
      // "modifyDirectOrderAction",
      // "getDirectOrderAction",
    ]),

    ...mapActions("packages", [
      "modifyDirectOrderAction",
      "getDirectOrderAction",
    ]),

    ...mapActions("services", ["getRecurringServicesAction"]),

    ...mapActions("customers", ["getCustomersAction", "getCustomersEmployeesWithoutPaginationAction"]),

     getClientEmployees() {
      this.getCustomersEmployeesWithoutPaginationAction()
        .then(response => {
        response.data.forEach(element => {
            this.employeeOptions.push({
              id: element.id,
              name: element.full_name,
            })
          });
          console.log('Clients', this.employeeOptions)
          
        })
        .catch(error => {
        console.log(error)
      })
    },

    configProAddressField() {
      const input = document.getElementById("address");
      const options = {
        types: ["establishment"],
        componentRestrictions: { country: ["BJ"] },
      };
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(input, options);

      this.autocomplete.addListener("place_changed", () => {
        const place = this.autocomplete.getPlace();
        this.payload.address = place.name;
      });
    },

    applyGetDirectOrderData() {
      this.isDirectOrderDataLoading = true;
      const directOrderId = this.$route.params.id;
      this.getDirectOrderAction(directOrderId)
        .then((response) => {
          this.isDirectOrderDataLoading = false;
          console.log(response.data);
          this.payload = {
            search_employee: response.data.search_employee ? 1 : 0,
            service_id: response.data.recurring_service_id,
            employee_salary: response.data.employee_salary,
            employee_brut_salary: response.data.employee_brut_salary,
            intervention_frequency: response.data.intervention_frequency,
            address: response.data.address,
            cnss: response.data.cnss ? 1 : 0,
            budget_is_fixed: response.data.budget_is_fixed ? 1 : 0,
            placement_fees_is_exonerated: response.data.placement_fees_is_exonerated ? 1 : 0,
            number_of_employees: response.data.number_of_employees,
            other_wish: response.data.other_wish,
          };
          
        })
        .catch((error) => {

          this.isDirectOrderDataLoading = false;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "TriangleIcon",
                text: error.response.message,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },
    resolveStatusText(element) {
      if (
        element.status === 0 &&
        element.placement_cost_paid === false &&
        element.searchEmployee === true
      ) {
        element.setStatus = "Non Payé";
        return element;
      }
      if (element.status === 0 && element.feedback === null) {
        element.setStatus = "En attente de feedback";
        return element;
      }

      if (
        element.status === 0 &&
        element.feedback != null &&
        element.recommended_employees_count === 0
      ) {
        element.setStatus = "En attente de recommandation";
        return element;
      }

      if (
        element.status === 0 &&
        element.feedback != null &&
        element.proposed_employees_count === 0
      ) {
        element.setStatus = "En attente de proposition";

        return element;
      }
      if (element.status === -1) {
        element.setStatus = "Résilié";

        return element;
      }
      if (element.status === 1) {
        element.setStatus = "Employé proposé";
        return element;
      }
      if (element.status === 2) {
        element.setStatus = "Contract émis";

        return element;
      }
      if (element.status === 3) {
        element.setStatus = "Contract approuvé";

        return element;
      }
      if (element.status === 4) {
        element.setStatus = "Actif";
        return element;
      }
      if (element.status === 5) {
        element.setStatus = "Terminée";
        return element;
      }
    },

    putRecurringOrdersInStoreAction(directOrder) {
      this.$store.commit("orders/SET_DIRECT_ORDER", directOrder);
      this.$router.push({
        name: "admin-orders-recurring-details",
        params: { id: this.$route.params.id },
        query: {packageId: this.$route.query.packageId}
      });
    },
    replaceNullsWithEmptyStrings(payload) {
    Object.keys(payload).forEach(key => {
      if (payload[key] === null) {
        payload[key] = " ";
      }
    });
  },

    applyModifyOrder() {
      this.$refs.modifyOrder.validate().then((success) => {
        if (success) {
          this.isModifyOrderLoading = true;
          if (this.payload.budget_is_fixed == 0) {
            this.payload.employee_brut_salary = ""
           
          }
          else {
            this.payload.employee_salary = ""
          }
          
          // if (this.payload.search_employee === 0) {
          //   this.payload.employee_phone_number = `229${this.phone.replace(
          //     /\s/g,
          //     ""
          //   )}`;
          // }
          this.replaceNullsWithEmptyStrings(this.payload);
          this.modifyDirectOrderAction({
            id: this.$route.params.id,
            payload: this.payload,
          })
            .then((response) => {
              console.log('reponseeee',response)
              this.isModifyOrderLoading = false;
              this.directOrder = response.data;
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Succès",
                    icon: "CheckIcon",
                    text: response.message,
                    variant: "success",
                  },
                },
                {
                  position: "top-center",
                }
              );
              this.getDirectOrderAction(this.directOrder.id).then((response) => {
                this.directOrder = response.data;
                this.directOrder = this.resolveStatusText(this.directOrder);
                this.putRecurringOrdersInStoreAction(this.directOrder)
              });
              // this.$router.push(`/orders/recurrent/${this.$route.params.id}/details?packageId=9`)
            })
            .catch((error) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Erreur",
                    icon: "TriangleIcon",
                    text: error.response.message,
                    variant: "danger",
                  },
                },
                {
                  position: "top-center",
                }
              );
              this.isModifyOrderLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
