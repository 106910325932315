var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isDirectOrderDataLoading)?_c('div',{staticClass:"d-flex justify-content-center mb-1",staticStyle:{"padding-top":"30vh"}},[_c('b-spinner',{staticClass:"align-middle text-info"})],1):_c('div',[_c('b-breadcrumb',{staticClass:"mb-2 "},[_c('b-breadcrumb-item',{attrs:{"to":"/orders/recurring"}},[_vm._v("Packages")]),_c('b-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'commande', params: { id: this.$route.query.packageId } }}},[_vm._v("Commandes")])],1),_c('b-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'admin-orders-recurring-details', params: { id: this.$route.params.id }, query: { packageId: this.$route.query.packageId } }}},[_vm._v("Détails")])],1),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("Modifier")])],1),_c('b-card',[_c('validation-observer',{ref:"modifyOrder",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('b-form',{attrs:{"id":"new-client-form"},on:{"submit":function($event){$event.preventDefault();return _vm.applyModifyOrder()}}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Recherche ou gestion d'employé","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing custom-control-success",attrs:{"options":_vm.typeOptions,"state":errors.length > 0 ? false : null,"value-field":"item","text-field":"name"},model:{value:(_vm.payload.search_employee),callback:function ($$v) {_vm.$set(_vm.payload, "search_employee", $$v)},expression:"payload.search_employee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Service recherché","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Service recherché","label-for":"services"}},[_c('v-select',{attrs:{"id":"role","reduce":function (service) { return service.id; },"label":"name","options":_vm.recurringServicesOption,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [(_vm.isServicesRecurringWithoutPaginationLoading)?_c('b-spinner',{staticClass:"align-middle text-info",staticStyle:{"width":"2.5rem","height":"2.5rem"}}):_c('span',{staticClass:"mt-1 font-medium-1"},[_vm._v("Ce service n'existe pas")])]},proxy:true}],null,true),model:{value:(_vm.payload.service_id),callback:function ($$v) {_vm.$set(_vm.payload, "service_id", $$v)},expression:"payload.service_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.payload.budget_is_fixed === 1? 'Budget global':'Salaire net',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.payload.budget_is_fixed === 1? 'Budget global':'Salaire net',"label-for":_vm.payload.budget_is_fixed === 1?'employee_brut_salary': 'employee_salary'}},[_c('b-form-input',{attrs:{"id":_vm.inputId,"type":"number","state":errors.length > 0 ? false : null,"placeholder":_vm.payload.budget_is_fixed === 1? 'Entrez le buget global': 'Entrez le salaire net de l\'employé'},model:{value:(_vm.InputVmodel),callback:function ($$v) {_vm.InputVmodel=$$v},expression:"InputVmodel"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Fréquence d'intervention","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fréquence d'intervention","label-for":"intervention-frequency"}},[_c('b-form-select',{attrs:{"id":"intervention-frequency","options":_vm.interventionFrequencyOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.payload.intervention_frequency),callback:function ($$v) {_vm.$set(_vm.payload, "intervention_frequency", $$v)},expression:"payload.intervention_frequency"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Nombre d'employé","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nombre d'employé","label-for":"number_of_employees"}},[_c('b-form-input',{attrs:{"id":"number_of_employees","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Entrez le nombre d'employé"},model:{value:(_vm.payload.number_of_employees),callback:function ($$v) {_vm.$set(_vm.payload, "number_of_employees", $$v)},expression:"payload.number_of_employees"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.payload.search_employee === 0)?_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{staticClass:"pr-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Employee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Employee","label-for":"Employee"}},[_c('v-select',{attrs:{"multiple":"","id":"employee","reduce":function (employee) { return employee.id; },"label":"name","options":_vm.employeeOptions,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [(_vm.isCustomersWithoutPaginationLoading)?_c('b-spinner',{staticClass:"align-middle text-info",staticStyle:{"width":"2.5rem","height":"2.5rem"}}):_c('div',[_c('span',{staticClass:"mt-1 font-medium-1"},[_vm._v("Aucun employé ne correspond à ces détails")])])]},proxy:true}],null,true),model:{value:(_vm.payload.employees),callback:function ($$v) {_vm.$set(_vm.payload, "employees", $$v)},expression:"payload.employees"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Cnss","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Déclarer à la cnss","label-for":"cnss"}},[_c('b-form-select',{attrs:{"id":"cnss","options":_vm.cnssOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.payload.cnss),callback:function ($$v) {_vm.$set(_vm.payload, "cnss", $$v)},expression:"payload.cnss"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"quartier du client","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Quartier du client","label-for":"address"}},[_c('b-form-input',{attrs:{"id":"address","placeholder":"Quartier du client","state":errors.length > 0 ? false : null,"autocomplete":"off"},model:{value:(_vm.payload.address),callback:function ($$v) {_vm.$set(_vm.payload, "address", $$v)},expression:"payload.address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.$route.query.exoneration == 'false')?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"exoneration"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Exonération des frais de placement","label-for":"placement_fees_is_exonerated"}},[_c('b-form-select',{attrs:{"id":"placement_fees_is_exonerated","options":_vm.exonerationOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.payload.placement_fees_is_exonerated),callback:function ($$v) {_vm.$set(_vm.payload, "placement_fees_is_exonerated", $$v)},expression:"payload.placement_fees_is_exonerated"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.payload.cnss === 0)?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"budget_fixe"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Budget fixe","label-for":"budget_is_fixed"}},[_c('b-form-select',{attrs:{"id":"budget_is_fixed","options":_vm.fixedBudgetOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.payload.budget_is_fixed),callback:function ($$v) {_vm.$set(_vm.payload, "budget_is_fixed", $$v)},expression:"payload.budget_is_fixed"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Cahier de charges","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cahier de charges","label-for":"needs"}},[_c('b-form-textarea',{attrs:{"id":"needs","placeholder":"Cahier de charges du client","rows":"5","no-resize":""},model:{value:(_vm.payload.other_wish),callback:function ($$v) {_vm.$set(_vm.payload, "other_wish", $$v)},expression:"payload.other_wish"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":_vm.isModifyOrderLoading}},[(_vm.isModifyOrderLoading)?_c('div',[_c('span',[_vm._v(" Chargement ... ")]),_c('b-spinner',{attrs:{"small":""}})],1):_c('span',{staticClass:"text-nowrap font-medium-1"},[_vm._v("Modifier")])])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }